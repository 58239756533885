import MDRouter from "@/service/router";
import { mdToast } from "../../utils/tools";
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    is_idea: {
      type: Boolean,
      default: false
    },
    is_can_share: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    is_preview() {
      return this.$route.query.token ? true : false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      let parent = this.$refs.box.parentNode;
      parent.addEventListener('scroll', this.styleFun);
    });
  },
  beforeUnmount() {
    let parent = this.$refs.box.parentNode;
    parent.removeEventListener('scroll', this.styleFun);
  },
  methods: {
    styleFun() {
      let scrollY = this.$refs.box.parentNode.scrollTop;
      // let scrollY = window.scrollY
      let base = 100;
      let dom = this.$refs.btnCompose;
      let name = this.$refs.proName;
      let rate = scrollY / base;
      dom.style.backgroundColor = `rgba(255, 255, 255, ${rate})`;
      name.style.opacity = rate > 1 ? 1 : rate;
      let item = document.querySelectorAll('.btn-item');
      if (dom && rate > 1) {
        dom.style.boxShadow = '0px 1px 0px 0px #F2F2F2';
        item.forEach(ele => {
          ele.style.border = 'none';
        });
      } else {
        dom.style.boxShadow = '0px 1px 0px 0px rgba(0, 0, 0, 0)';
        item.forEach(ele => {
          ele.style.border = '2px solid #F2F2F2';
        });
      }
    },
    onBack() {
      if (this.is_preview) {
        return false;
      }
      MDRouter.goBack();
    },
    onLinkHome() {
      if (this.is_preview) {
        return false;
      }
      MDRouter.goHome();
    },
    onShare() {
      if (this.is_preview) {
        return false;
      }
      if (this.is_can_share) {
        // eslint-disable-next-line no-undef
        userHeader.shareShow();
      } else {
        mdToast('当前创意“审核中/审核未通过/待修改”，暂不可分享');
      }
    }
  }
};