import Error from "@@/common/Error.vue";
import MDRouter from "@/service/router";
import env from "md-base-tools/env";
import redirect from "@/service/redirect";
import { mapState } from "vuex";
import domain from "@/service/domain";
import { getSchemeUrl } from "@/utils/toolsApi";
import CompHeader from "@@/project/header.vue";
import CompIdeaTopSwiper from "@@/idea/topSwiper.vue";
import CompCommentList from "@@/idea/commentList.vue";
import CompUpdateList from "@@/idea/updateList.vue";
import CompToPay from "@@/idea/toPayDialog.vue";
import cont_match_reg from "@/utils/editor.js";
import { getIdeaIndexInfo, getIdeaUpdateList, postIdeaSubscribe, getHistoryIdeaContent, postIdeaFavor, getRelationInfo, postUserFollow, getRecommentReplyList, getReplyList, getProjectContent //获取众筹基本信息
} from "@/api";
import { showConfirmDialog } from 'vant';
import { mdToast } from "../../utils/tools";
// import Clipboard from 'clipboard';
export default {
  components: {
    Error,
    CompHeader,
    CompIdeaTopSwiper,
    CompCommentList,
    CompUpdateList,
    CompToPay
  },
  computed: {
    ...mapState({
      ifLogin: state => state.user.ifLogin,
      // 是否登录
      userId: state => state.user.userId
      // linkUrl: state => state.user.linkUrl,
    }),
    fovor_total() {
      let total = 0;
      if (this.idea_info.idea_favor_info && this.idea_info.idea_favor_info.favor_count > 0) {
        total = this.idea_info.idea_favor_info.favor_count;
      }
      return total;
    },
    is_attitude() {
      let result = false;
      if (this.idea_info.idea_favor_info && this.idea_info.idea_favor_info.favor_status == 1) {
        result = true;
      }
      return result;
    },
    is_example() {
      return this.$route.query.is_example ? true : false;
    }
  },
  data() {
    return {
      showError: false,
      interactLogo: {
        'attitude_type_1': 'https://p6.moimg.net/mdwxapp/idea_attitude_a_001.png',
        'attitude_type_2': 'https://p6.moimg.net/mdwxapp/idea_attitude_a_002.png',
        'attitude_type_3': 'https://p6.moimg.net/mdwxapp/idea_attitude_a_003.png',
        'attitude_type_4': 'https://p6.moimg.net/mdwxapp/idea_attitude_a_004.png'
      },
      showMessage: "",
      idea_info: {},
      user_info: {},
      showFavor: false,
      favorType: "success",
      weapp: this.$route.query.weapp != "off",
      //是否打开自动调起小程序，默认是
      app_guide: this.$route.query.app_guide != "off",
      //是否打开APP引导下载，默认是
      mina_focus: this.$route.query.mina_focus == "true",
      //是否将用户购买行为强制改为调起小程序，默认否
      share_info: {},
      // 分享详情
      update_list: [],
      page: 0,
      page_size: 10,
      is_next: true,
      updateLoading: false,
      hasHistoryData: false,
      // 是否请求到历史富文本数据
      isFollow: false,
      // 是否关注
      recommend_id: 0,
      // 置顶评论id
      comment_total: 0,
      // 评论总数
      comment_list: [],
      // 评论列表
      proInfo: {},
      // 众筹详情
      showPayVisible: false //众筹弹窗
    };
  },
  async beforeMount() {
    // eslint-disable-next-line no-undef
    userHeader.openapp.style.bottom = "70px";
    const idea_id = this.$route.params.id ? this.$route.params.id.replace(".html", "") : "";
    this.idea_id = idea_id;
    await this.getInfo();
    // 打点
    this.sensors.track("IdeaDetail", {
      page_source: "",
      position_source: "",
      item_name: this.idea_info.title || "",
      item_id: this.idea_id || "",
      sec_business_line: this.idea_info.pro_class || 102,
      category: this.idea_info.category || "",
      product_name: "",
      spu_id: "",
      sku_id: "",
      store_id: "",
      brand: ""
    });
    this.setShare();

    // eslint-disable-next-line no-undef
    userHeader.getMdlink({
      type: "idea",
      id: idea_id
    });
  },
  unmounted() {
    // eslint-disable-next-line no-undef
    userHeader.openapp.style.bottom = "0.27rem";
  },
  methods: {
    // cp关注信息
    async getRelationCpInfo() {
      const {
        status,
        data
      } = await getRelationInfo({
        to_user_id: this.idea_info.index_cp_info.user_id
      });
      if (status == 0 && data) {
        this.isFollow = data.relation <= 0 ? false : true;
      }
    },
    // 获取更新列表
    async getUpdateList() {
      this.page++;
      const {
        status,
        data
      } = await getIdeaUpdateList({
        idea_id: this.idea_id,
        page: this.page,
        page_size: this.page_size
      });
      console.log(this.page);
      if (status == 0 && data && data.list) {
        this.updateLoading = false;
        data.list.forEach(ele => {
          ele.if_video_play = false;
          let dateObj = new Date(ele.create_time.replace(/-/g, '/'));
          ele.isOrderThanNow = dateObj.getFullYear() < new Date().getFullYear();
          ele.year = dateObj.getFullYear();
          ele.month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : '0' + (dateObj.getMonth() + 1);
          ele.date = dateObj.getDate() > 9 ? dateObj.getDate() : '0' + dateObj.getDate();
          this.update_list.push(ele);
        });
        this.is_next = data.is_next;
        // 请求历史数据的富文本
        if (!data.is_next && this.idea_info.is_history_data) {
          await this.getHistoryContent();
        }
      } else {
        this.is_next = false;
        await this.getHistoryContent();
      }
    },
    // 获取历史富文本
    async getHistoryContent() {
      const {
        data,
        status,
        message
      } = await getHistoryIdeaContent({
        idea_id: this.idea_id
      });
      if (status == 0) {
        if (this.$refs.editorBox && data && data.content) {
          this.hasHistoryData = true;
          this.$refs.editorBox.appendChild(cont_match_reg(data.content));
        }
      } else {
        mdToast(message);
      }
    },
    // 获取创意详情
    async getInfo() {
      const {
        status,
        data,
        message
      } = await getIdeaIndexInfo({
        idea_id: this.idea_id
      });
      if (status != 0) {
        this.showError = true;
        this.showMessage = message || "项目不存在";
        return;
      } else {
        if (data.status == 33 && data.is_set_quick_jump == 1 && data.pro_id && !this.is_example) {
          MDRouter.goProInfo(data.pro_id);
        } else {
          this.idea_info = data;
          if (data.status == 33 && data.is_set_quick_jump == 0 && data.pro_id && !this.is_example) {
            await this.getProjectInfoDialog(data.pro_id);
          }
          this.comment_total = data.comment_count;
          await this.getCommonList();
          if (this.ifLogin) {
            await this.getRelationCpInfo();
          }
          await this.getUpdateList();
        }
      }
      if (this.weapp) {
        this.getWxScheme();
      }
    },
    // list 方法
    onLoad() {
      this.getUpdateList();
    },
    // 去app
    goToApp() {
      // eslint-disable-next-line no-undef
      location.href = userHeader.mdlink;
    },
    // 获取小程序scheme url
    async getWxScheme() {
      if (env.isMobile() && !(env.isWeibo() || env.isWxApp() || env.isModianIos() || env.isModianAndroid())) {
        let url = "";
        if (sessionStorage.getItem(this.idea_id)) {
          const obj = JSON.parse(sessionStorage.getItem(this.idea_id));
          let time = Number(obj.initial_time);
          const now = Number(Date.now());
          if (obj.scheme_url && now - time < 23 * 60 * 60 * 1000) {
            // 23小时失效
            url = obj.scheme_url;
          } else {
            url = await this.getScheme();
          }
        } else {
          url = await this.getScheme();
        }
        if (url) {
          location.href = url;
        }
      }
    },
    async getScheme() {
      // const query = this.$route.query || {}
      // for (let key in query) {
      //     str += `&${key}=${query[key]}`
      // }
      let str = window.location.search ? window.location.search.substr(1) : "";
      const param = {
        path: "/pages/ideaPages/idea_detail/idea_detail",
        query: `idea_id=${this.idea_id}&${str}`
      };
      const option = {
        jump_wxa: JSON.stringify(param)
      };
      const url = await getSchemeUrl(option);
      if (!url) return false;
      sessionStorage.setItem(this.idea_id, JSON.stringify({
        scheme_url: url,
        initial_time: Date.now()
      }));
      return url;
    },
    // 分享
    async setShare() {
      // 获取分享详情
      // const res = await getShareInfo({
      //   id: this.idea_id,
      //   type: '57',
      //   user_id: this.userId || ''
      // })
      // if (res.status != 0) return
      // this.share_info = res.data

      this.share_info = {
        image_url: this.idea_info.logos[0].url,
        title: this.idea_info.title
      };
      let url = `${domain.wap}/idea/${this.idea_id}.html?&utm_source=Othershare&utm_content=${this.userId}`;
      let share_option = {
        url,
        img: this.share_info.image_url,
        msg_des: `我在摩点网发现一个有意思的创意项目：【${this.share_info.title}】看看是否感兴趣？${url}`,
        qq_des: this.share_info.title,
        qq_share_url: `${url}`,
        qq_title: this.share_info.title,
        qr_img: "",
        qzone_share_url: `${url}`,
        share_channel: "",
        share_url: `${url}`,
        sharelink_url: `${url}`,
        weibo_des: `我在摩点网发现一个有意思的创意项目：【${this.share_info.title}】看看是否感兴趣？${url}（分享自@摩点）`,
        weibo_share_url: `${url}`,
        weibo_title: "",
        weixin_share_url: `${url}`,
        wxTimeline_share_url: `${url}`,
        wx_des: this.share_info.title,
        wx_title: `${this.share_info.title} ｜ 摩点`,
        mini_programs_title: `${this.share_info.title} ｜ 创意${url}`,
        mina_share_img: this.share_info.image_url,
        mina_app_name: 'gh_7210ac3f1f08',
        mina_share_path: `/pages/ideaPages/idea_detail/idea_detail?idea_id=${this.idea_id}`
      };
      // eslint-disable-next-line no-undef
      userHeader.setShareInfo(share_option);
    },
    // 点击前往全部项目
    goProjectList() {
      localStorage.setItem("list_category", this.idea_info.category_en);
      this.$router.push({
        name: "project_list"
      });
    },
    // 查看更新列表
    goUpdateList() {
      MDRouter.goUpdateList({
        pro_id: this.idea_info.pro_id
      });
    },
    // 订阅按钮的点击事件
    async confirm() {
      if (this.idea_info.status == 21) {
        return false;
      }
      if (this.idea_info.status == 33) {
        MDRouter.goProInfo(this.idea_info.pro_id);
      } else {
        if (!this.ifLogin) {
          return redirect.to("login");
        }
        await this.idea_subscribe();
      }
    },
    // 去购买支持
    toPay() {
      MDRouter.goProInfo(this.idea_info.pro_id);
    },
    // 订阅创意
    async idea_subscribe() {
      const {
        status,
        data,
        message
      } = await postIdeaSubscribe({
        idea_id: this.idea_id,
        subscribe_type: this.idea_info.is_subscribe_idea == 1 ? 0 : 1
      });
      if (status != 0) {
        mdToast(message);
        return;
      } else {
        this.idea_info.is_subscribe_idea = data.subscribe_status;
        if (data.subscribe_status) {
          this.idea_info.idea_subscribe_count++;
        } else {
          this.idea_info.idea_subscribe_count - 1 < 0 ? 0 : this.idea_info.idea_subscribe_count--;
        }
      }
    },
    // 创意点赞
    async idea_favor() {
      if (!this.ifLogin) {
        return redirect.to("login");
      }
      if (this.is_attitude && this.idea_info.status == 33) {
        showConfirmDialog({
          message: '创意已完成，取消后不可再次表态',
          cancelButtonText: '不取消 ',
          confirmButtonText: '取消表态',
          confirmButtonColor: '#00c4a1'
        }).then(() => {
          this.idea_favor_callback();
        });
      } else {
        this.idea_favor_callback();
      }
    },
    async idea_favor_callback() {
      const {
        status,
        data,
        message
      } = await postIdeaFavor({
        idea_id: this.idea_id,
        favor_type: 1
      });
      if (status != 0) {
        mdToast(message);
        return;
      } else {
        if (!this.is_attitude) {
          mdToast('感谢看好～创意历程有更新会及时通知你');
        }
        this.idea_info.idea_favor_info = data;
      }
    },
    // 前往个人空间
    goUserCenter() {
      if (this.idea_info.index_cp_info && this.idea_info.index_cp_info.user_id) {
        let path = '/user/homePage/' + this.idea_info.index_cp_info.user_id;
        this.$router.push(path);
      }
    },
    // 关注
    async UserFollow() {
      const {
        status,
        message
      } = await postUserFollow({
        to_user_id: this.idea_info.index_cp_info.user_id,
        type: this.isFollow ? '1' : '2'
      });
      if (status != 0) {
        mdToast(message);
        return;
      } else {
        this.isFollow = !this.isFollow;
        mdToast(this.isFollow ? "关注成功" : "取消关注成功");
      }
    },
    // 评论相关
    async getCommonList() {
      const {
        status,
        data
      } = await getRecommentReplyList({
        post_id: this.idea_info.post_id
      });
      if (status == 0 && data) {
        this.comment_list.push(data);
        this.recommend_id = data.id;
      }
      await this.getExtraReplyList();
    },
    // 正常评论
    async getExtraReplyList() {
      let option = {
        moxi_post_id: this.idea_info.post_id,
        post_id: this.idea_info.post_id,
        page: 1,
        page_size: 5,
        request_id: 0
      };
      const {
        status,
        data
      } = await getReplyList(option);
      if (status == 0 && data && data.list) {
        if (this.recommend_id) {
          for (let i = 0, j = data.list.length; i < j; i++) {
            if (this.recommend_id != data.list[i].id && this.comment_list.length < 2) {
              this.comment_list.push(data.list[i]);
            }
          }
        } else {
          this.comment_list.push(...data.list.splice(0, 2));
        }
      }
    },
    // 众筹信息
    async getProjectInfoDialog(id) {
      const {
        status,
        data
      } = await getProjectContent({
        pro_id: id
      });
      if (status == 0 && data && data.product_info) {
        this.proInfo.logo = data.product_info.logo;
        this.proInfo.title = data.product_info.title;
        this.showPayVisible = true;
      }
    }
  }
};