import { Overlay } from 'vant';
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    proInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    [Overlay.name]: Overlay
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    topay() {
      this.$emit("confirm");
    },
    close() {
      this.$emit("close");
    }
  }
};