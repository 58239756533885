import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-56cfc15c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "comment_list"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "content render-line-clamp"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.comment_list, (item, commentIndex) => {
    return _openBlock(), _createElementBlock("div", {
      class: "item",
      key: commentIndex
    }, [_createElementVNode("img", {
      src: `${_ctx.$imgUrlFilter(item.user_info && item.user_info.icon || '', 120)}`
    }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, _toDisplayString(item.content), 1), _createElementVNode("div", {
      class: "zan",
      onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => $options.goToApp && $options.goToApp(...args), ["stop"]))
    }, "赞")]);
  }), 128))]);
}