import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-057ba188"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "top-swiper-box"
};
const _hoisted_2 = {
  class: "top-swiper-info"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "top-swiper-info-nums"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.logos && $props.logos.length > 0 ? (_openBlock(), _createBlock(_component_van_swipe, {
    key: 0,
    "show-indicators": false,
    onChange: $options.swiperChange,
    loop: false
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.logos, image => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: image
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: _ctx.$imgUrlFilter(image.url ? image.url : require('@assets/images/placeholder/default_1x1@2x.png'), 750)
        }, null, 8, _hoisted_3)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["onChange"])) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _ctx.$imgUrlFilter($props.logos[0] ? $props.logos[0].url : require('@assets/images/placeholder/default_1x1@2x.png'), 750)
  }, null, 8, _hoisted_4)), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.logos, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(['item', $options.classObj, {
        'active': $data.currentIndex == index
      }]),
      key: index
    }, null, 2);
  }), 128))])]), _createElementVNode("div", {
    class: "bg",
    style: _normalizeStyle(`background-image: url(${_ctx.$imgUrlFilter($props.logos[$data.currentIndex] ? $props.logos[$data.currentIndex].url : require('@assets/images/placeholder/default_1x1@2x.png'), 750)})`)
  }, null, 4)]);
}