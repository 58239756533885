export default {
  props: {
    logos: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      currentIndex: 0
    };
  },
  computed: {
    classObj() {
      return 'item-' + this.logos.length;
    }
  },
  methods: {
    swiperChange(index) {
      this.currentIndex = index;
    }
  }
};